import React from 'react'

import SEO from '../components/seo'
import Services from '../components/services'
import Technologies from '../components/technologies'
import Header from '../components/header'
import Industries from '../components/industries'
import Blog from '../components/blog'

const IndexPage = () => (
  <>
    <SEO
      title="BluePes - One-stop IT outsourcing"
      keywords={[`bluepes`, `company`, `outsourcing`, `IT`]} />
    <Header />
    <div id="content"></div>
    <Services />
    <Industries />
    <Technologies />
    <Blog />
  </>
)

export default IndexPage
