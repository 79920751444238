import React, { Component } from 'react'

import { graphql, StaticQuery } from 'gatsby'
import RightArrow from '../assets/icons/right-arrow.svg'
import LeftArrow from '../assets/icons/left-arrow.svg'
import Img from 'gatsby-image'

/**
 * Component represents data about industries
 */

class Industries extends Component {
  constructor (props) {
    super(props)
    this.state = {
      numberActivePage: 1
    }
  }

  numberPageHandler = e => this.setState({ numberActivePage: +e.target.id })

  arrowLeftPageHandler = () =>
    this.setState({
      numberActivePage: this.state.numberActivePage === 1
        ? this.state.numberActivePage : this.state.numberActivePage - 1
    })

  arrowRightPageHandler = () =>
    this.setState({
      numberActivePage: this.state.numberActivePage === 5
        ? this.state.numberActivePage : this.state.numberActivePage + 1
    })

  render () {
    const { numberActivePage } = this.state

    return (
      <StaticQuery query={industriesQuery} render={data => {
        const { totalCount, edges } = data.allMarkdownRemark
        let pageNumberArray = Array.from({ length: totalCount })
          .map((_, i) => i < 9 ? '0' + (i + 1) : '' + (i + 1))

        return (
          <section className="industries" id="industries">
            <div className="industries-wrapper wrapper">
              <h1 className="section-header">INDUSTRIES</h1>
              <div className="industries-content">
                <div className="industries-item">
                  <h1 className="industries-item-header">
                    {
                      edges[numberActivePage - 1]
                        .node.frontmatter.title
                    }
                  </h1>
                  <div
                    className="industry-item-description"
                    dangerouslySetInnerHTML={{
                      __html: edges[numberActivePage - 1].node.html
                    }}
                  />
                </div>
                <div className="industries-header-paginator">
                  {totalCount > 1 && (
                    <div className="industries-paginator">
                      <div className="arrow">
                        <LeftArrow
                          disabled={numberActivePage === 1}
                          onClick={this.arrowLeftPageHandler}
                        />
                      </div>
                      <ul className="page-numbers">
                        {pageNumberArray.map((pageNumber, index) => {
                          return (
                            <li
                              className={`page-number ${ numberActivePage === index + 1
                                ? 'page-number-active' : '' }`}
                              key={index}
                              id={index + 1}
                              onClick={this.numberPageHandler}
                            >
                              {pageNumber}
                            </li>
                          )
                        })
                        }
                      </ul>
                      <div className="arrow">
                        <RightArrow
                          disabled={!(numberActivePage < pageNumberArray.length)}
                          onClick={this.arrowRightPageHandler}
                        />
                      </div>
                    </div>
                  )}
                  <div className="industry-item-img">
                    <Img
                      fluid={
                        edges[numberActivePage - 1]
                          .node.frontmatter.image.childImageSharp.fluid
                      }
                      alt={edges[numberActivePage - 1]
                        .node.frontmatter.title}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      }}
      />
    )
  }
}

export const industriesQuery = graphql`
  query {
    allMarkdownRemark(
      sort: {
        fields: [frontmatter___order]
        order: ASC
      }
      filter: {fileAbsolutePath: {regex: "/content/industries/"}}
    ) {
    totalCount 
      edges {
        node {
          id
          frontmatter {
            title
            order
            image {
              childImageSharp {
                fluid(
                  maxWidth: 712, 
                  quality: 80, 
                  srcSetBreakpoints: [344, 448, 544, 608, 712]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          html
        }
      }
    }
  }
`
export default Industries
