import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

// will need this import in the future
// import arrowImage from '../images/services-arrow.png'

/**
 * Component represents data about services
 */

const Services = () => (
  <StaticQuery query={serviceQuery} render={data => {
    return (
      <section className="services" id="services">
        <div className="services-wrapper wrapper">
          <h1 className="section-header">SERVICES</h1>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div className="service-item" key={node.id}>
              <div className="service-item-number-mobile">
                {`0${ node.frontmatter.order }`}
              </div>
              <div className="service-item-img">
                <Img
                  fluid={node.frontmatter.image.childImageSharp.fluid}
                  alt={node.frontmatter.title} />
              </div>
              <div className="service-item-info">
                <div className="service-item-number-desktop">
                  {`0${ node.frontmatter.order }`}
                </div>
                <h1 className="service-item-header">
                  {node.frontmatter.title}
                </h1>
                <div
                  className="service-item-description"
                  dangerouslySetInnerHTML={{ __html: node.html }} />
                {/* <img src={arrowImage} alt="" className="service-arrow-link" /> */}
              </div>
            </div>
          ))}
        </div>
      </section>
    )
  }} />
)

export default Services

export const serviceQuery = graphql`
  query {
    allMarkdownRemark(
      sort: {
        fields: [frontmatter___order]
        order: ASC
      }
      filter: {fileAbsolutePath: {regex: "/content/services/"}}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            order
            image {
              childImageSharp {
                fluid(
                  maxWidth: 715, 
                  quality: 85, 
                  srcSetBreakpoints: [344, 448, 544, 608, 712]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          html
        }
      }
    }
  }
`
