import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Post from '../components/Post/Post'
import RightArrow from '../assets/icons/right-arrow.svg'

/**
 * Component represents articles by the blog"
 */

const Blog = () => (
  <StaticQuery query={articlesQuery} render={data => {
    const landingArticles = Object.values(data.markdownRemark.frontmatter)
    const filteredArticles = data
      .allMarkdownRemark.edges
      .filter(({ node }) => landingArticles.some(title => title === node.frontmatter.title))
    return (
      <section className="blog" id="blog">
        <div className="blog-wrapper wrapper">
          <h1 className="section-header">LAST FROM BLOG</h1>
          <div className="blog-articles-wrapper">
            {filteredArticles.map(({ node }) => {
              return (
                <Post
                  key={node.id}
                  title={node.frontmatter.title}
                  slug={`/blog/${ node.fields.slug }`}
                  image={node.frontmatter.image.childImageSharp.fluid}
                  date={node.frontmatter.date}
                />
              )
            })}
          </div>
          <div className="link-to-blog-page">
            <Link to="/blog/">
              GO TO BLOG&nbsp;&nbsp;
              <RightArrow className="link-arrow"/>
            </Link>
          </div>
        </div>
      </section>
    )
  }} />
)

export default Blog

export const articlesQuery = graphql`
  query {
    allMarkdownRemark (
      limit: 1000
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
      filter: {fileAbsolutePath: {regex: "/content/blog/"}}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMM YYYY")
            image {
              childImageSharp {
                fluid(
                  maxWidth: 456, 
                  maxHeight: 456, 
                  quality: 80, 
                  cropFocus: CENTER,
                  srcSetBreakpoints: [224, 288, 344, 352, 456]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    markdownRemark (
      fileAbsolutePath: {regex: "/partial/landing-articles.md/"}
    ) {
      frontmatter {
        firstArticle
        secondArticle
        thirdArticle
      }
    }
  }
`
