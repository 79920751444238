import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Header = () => (
  <StaticQuery
    query={headerQuery}
    render={data => {
      const { frontmatter, html } = data.markdownRemark
      return (
        <header className="header">
          <div className="header-wrapper wrapper">
            <div className="header-content">
              <div className="main-site-header"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <div className="header-text-1">
                {frontmatter.headerText}
              </div>
              <div className="header-image-mobile">
                <Img
                  fluid={frontmatter.image.childImageSharp.fluid}
                  alt="bluepes company"/>
              </div>
              <div className="subheader-wrapper">
                <div className="rectangle">
                  <span/>
                </div>
                <div className="header-text-2">
                  <div className="main-site-subheader">
                    {frontmatter.subHeader}
                  </div>
                  <div className="header-text-3">
                    {frontmatter.subHeaderText}
                  </div>
                </div>
              </div>
            </div>
            <div className="header-image-desktop">
              <Img
                fluid={frontmatter.image.childImageSharp.fluid}
                alt="bluepes company"
              />
            </div>
          </div>
        </header>
      )
    }}
  />
)

export default Header

export const headerQuery = graphql`
  query {
    markdownRemark(
      fileAbsolutePath: {regex: "/partial/header.md/"}
    ) {
      frontmatter {
        headerText
        subHeader
        subHeaderText
        image {
          childImageSharp {
            fluid(maxWidth: 970, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      html
    }
  } 
`
