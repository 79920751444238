import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

/**
 * Component represents data about technologies
 */

const technologies = () => (
  <StaticQuery query={technologiesQuery} render={data => {
    return (
      <section className="technologies" id="technologies">
        <div className="technologies-wrapper wrapper">
          <h1 className="section-header">TECHNOLOGIES</h1>
          <div className="technology-item-wrapper">
            {data.allMarkdownRemark.edges.map(({ node }) => {
              return (
                <div className="technology-item" key={node.id}>
                  <div className="technology-item-image">
                    <img
                      src={require(`../assets/technology-${ node.frontmatter.imageName }.svg`)}
                      alt={node.frontmatter.title} />
                  </div>
                  <div
                    className="technology-item-description"
                    dangerouslySetInnerHTML={{ __html: node.html }} />
                </div>
              )
            })}
            {/* <div className="link-to-technologies-page">
              <span>more</span>
            </div> */}
          </div>
        </div>
      </section>
    )
  }} />
)

export default technologies

export const technologiesQuery = graphql`
  query {
    allMarkdownRemark (
      sort: {
        fields: [frontmatter___order]
        order: ASC
      }
      filter: {fileAbsolutePath: {regex: "/content/technologies/"}}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            order
            imageName
            unknown
            some {
              name
            }
          }
          html
        }
      }
    }
  }
`
